import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import ActionButton from "../components/actionButton"

const Heimgottesdienst = ({data}) => {
  return (
    <Layout title="Heimgottesdienst" slogan="Evangelischer & Katholischer Heimgottedienst" headerImage={data.image1.childImageSharp.fluid}>
        <div className="grid grid-2">
          <div>
            <Image fluid={data.image2.childImageSharp.fluid} style={{maxWidth: '250px', margin: '0 1em 1em 0'}} alt="Evangelischer Heimgottesdienst"/>
            <p>
              Alle 14 Tage finden die öffentlichen Heimgottesdienste im Altersheim Bannau statt. Die sozialdiakonische Mitarbeiterin und die Pfarrer gestalten diese Anlässe wechselweise.
            </p>
            <p>
              Weitere Informationen: <a href="https://www.evang-weinfelden.ch/heimgottesdienste">Evangelische Kirche Weinfelden</a>
              <br/><br/>
            </p>
            <ActionButton type="icon-pdf" address="/download/Evangelische-Gottesdienste.pdf" text="Termine Evangelische Heimgottesdienste" />
          </div>
          <div>
            <Image fluid={data.image3.childImageSharp.fluid} style={{maxWidth: '300px', margin: '1em 1em 1em 0'}} alt="Evangelischer Heimgottesdienst"/>
            <p>
              <br/>
              Katholische Kirche,<br/>
              Diakonische Mitarbeiterin,<br/>
              Ursi Häfner-Neubauer,<br/>
              Tel: 071 622 76 02 / 078 807 67 87
              <br/><br/><br/>
            </p>
            <ActionButton type="icon-pdf" address="/download/Katholische-Gottesdienste.pdf" text="Termine Katholische Heimgottesdienste" />
          </div>
        </div>        
    </Layout>
  ) 
}

export default Heimgottesdienst

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "kirche-evang-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image3: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "kirche-kath-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`